<template>
  <transition name="modal-fade">
  <div class="modal-backdrop">
    <div class="modal"
         role="dialog"
         >
         <div
         class="modal-header"
         >
         <h5 class='header'>
           Edit Card
         </h5>

           <button
         type="button"
         class="btn-close"
         @click="close"
         >
         x
           </button>
         </div>
      <section
         class="modal-body"
         id="modalDescription"
         >
         <form>
           <label for="title">Title</label>
           <input type="text" v-model="titletext">
           <label for="notes" v-bind:class="{ waitinghide: iswaiting }">Notes</label>
           <textarea v-model="notestext" v-bind:class="{ waitinghide: iswaiting }"></textarea>
           <label for="category" v-bind:class="{ waitinghide: iswaiting }">Category</label>
           <select v-model="selecttext" v-bind:class="{ waitinghide: iswaiting }">
             <option value="home">Home</option>
             <option value="anywhere">Anywhere</option>
             <option value="errands">Errands</option>
             <option value="work">Work</option>
           </select>
           <input class="update-btn" type="submit" value="Update Card" :disabled="subdisabled" @click="submit">
           <button type="button" class="delete-btn" v-on:click="rm_card">Delete Card</button>
         </form>
      </section>
    </div>
  </div>
  </transition>
</template>

<script>
import axios from 'axios'

export default {
  name: 'editmodal',
  data() {
    return {
      subdisabled: false,
      titletext: '',
      selecttext: 'Please select',
      notestext: ''
    }
  },
  props: {
    oldTitle: String,
    oldNotes: String,
    oldCategory: String,
    oldId: Number,
    iswaiting: {
      default: false
    }
  },
  watch: {
    oldTitle: function (val) {
      this.titletext = val
    },
    oldNotes: function (val) {
      this.notestext = val
    },
    oldCategory: function (val) {
      this.selecttext = val
    },
  },
  methods: {
    submit: function() {
      this.subdisabled = true;
      var newcat = this.selecttext.toLowerCase();
      var newtit = this.titletext;
      var newdesc = this.notestext;

      var out_data = new FormData();
      out_data.set('id', this.oldId);
      out_data.set('cat', newcat);
      out_data.set('title', newtit);
      out_data.set('desc', newdesc);

      axios.post(this.$parent.editcard_url,
        out_data
      ).then(response => {
        if (response.status == 200) {
          this.$parent.updateCard({"category": newcat, "title": newtit, "description": newdesc, "id": this.oldId}, this.$parent.confirmAction)
          this.subdisabled = false;
          this.close();
        }
        else {
          this.subdisabled = false;
        }
      }).catch(err => {
        this.subdisabled = false;
        window.console.log(err);
      })
    },
    rm_card: function() {
      this.subdisabled = true;
      var out_data = new FormData();
      out_data.set('id', this.oldId);

      axios.post(this.$parent.del_url,
        out_data
      ).then(response => {
        if (response.status == 200) {
          this.$parent.deleteCard(this.$parent.confirmAction)
          this.subdisabled = false;
          this.close();
        }
        else {
          this.subdisabled = false;
        }
      }).catch(err => {
        this.subdisabled = false;
        window.console.log(err);
      })

    },
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  height: fit-content;
  width: 450px;
  box-shadow: 2px 2px 20px 1px;
  border-radius: 5px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 10px;
  margin: 0px;
  text-align: center;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  background-color: #EEEEEE;
  display: flex;
  height: fit-content;
}

.modal-body {
  position: relative;
  padding: 0px 10px;
}

.update-btn {
  background-color: #3D96A6;
  color: white;
  border: none;
}

.update-btn:disabled {
  background-color: #6C757D;
  cursor: default;
}

.delete-btn {
  float: right;
  background-color: #F79C85;
  color: #404040;
  border: none;
}

.btn-close, .btn-close:hover {
  padding: 5px;
  height: fit-content;
  border: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #3D96A6;
  background: transparent;
}

.model-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
.waitinghide {
  display: none;
}
</style>
