<template>
  <div class="row">
    <div id="main-content">
      <a :href="link_url" target="_blank">
        <img id="poster-img" :src="img_url">
      </a>
    </div>
    <div id="rating">
      <h4>RATING: {{ rating }}</h4>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Memory',
  data() {
    return {
      link_url: '',
      img_url: '',
      rating: ''
    }
  },
  props: {
    dataurl: String
  },
  mounted: function() {
    axios.get(this.dataurl).then(
      response => (
        this.link_url = response.data['url'],
        this.img_url = response.data['img'],
        this.rating = response.data['rating']
      )
    )
  }
}
</script>

<style scoped>
body {
  background: #EEE;
}
.row {
  width: 100%;
  height: 480px;
  padding: 0px 5px 0px 5px;
  text-align: center;
}
#main-content {
  padding: 0px 0px 10px 0px;
  height: 445px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
#poster-img {
  max-width: 100%;
  max-height: 100%;
}
h4 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
</style>
