<template>
  <transition name="modal-fade">
  <div class="modal-backdrop">
    <div class="modal"
         role="dialog"
         >
         <div
         class="modal-header"
         >
         <h5 class='header'>
           Add New Card
         </h5>

           <button
         type="button"
         class="btn-close"
         @click="close"
         >
         x
           </button>
         </div>
      <section
         class="modal-body"
         id="modalDescription"
         >
         <form autocomplete="off">
           <label for="title">Title</label>
           <input type="text" v-model="titletext" placeholder="Story Title">
           <label for="notes" v-bind:class="{ waitinghide: iswaiting }">Notes</label>
           <textarea v-model="notestext" v-bind:class="{ waitinghide: iswaiting }"></textarea>
           <label for="category" v-bind:class="{ waitinghide: iswaiting }">Category</label>
           <select v-model="selecttext" v-bind:class="{ waitinghide: iswaiting }">
             <option disabled selected>Please select</option>
             <option value="home">Home</option>
             <option value="anywhere">Anywhere</option>
             <option value="errands">Errands</option>
             <option value="work">Work</option>
           </select>
           <input class="submit-btn" type="submit" value="Submit" :disabled="submitdisabled" @click="submit">
         </form>
      </section>
    </div>
  </div>
  </transition>
</template>

<script>
import axios from 'axios'
export default {
  name: 'modal',
  data() {
    return {
      subdisabled: false,
      titletext: '',
      selecttext: 'Please select',
      notestext: ''
    }
  },
  props: {
    iswaiting: {
      default: false
    }
  },
  computed: {
    submitdisabled: function () {
      if (this.iswaiting) {
        return this.titletext == '' || this.subdisabled
      }
      else {
        return this.titletext == '' || this.subdisabled || !(['home','anywhere','errands','work'].includes(this.selecttext))
      }
    }
  },
  methods: {
    submit: function() {
      this.subdisabled = true;
      var newcat = this.selecttext.toLowerCase();
      var newtit = this.titletext;
      var newdesc = this.notestext;

      if (this.iswaiting) {
        newcat = 'waiting';
        newdesc = '';
      }

      var out_data = new FormData();
      out_data.set('cat', newcat);
      out_data.set('title', newtit);
      out_data.set('desc', newdesc);

      axios.post(this.$parent.newcard_url,
        out_data
      ).then(response => {
        if (response.status == 200) {
          this.$parent.addCard({"category": newcat, "title": newtit, "description": newdesc, "id": response.data['id']}, this.$parent.confirmAction)
          this.subdisabled = false;
          this.titletext = '';
          this.notestext = '';
          this.selecttext = "Please select";
          this.close();
        }
        else {
          this.subdisabled = false;
        }
      }).catch(err => {
        this.subdisabled = false;
        window.console.log(err);
      })
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  height: fit-content;
  width: 450px;
  box-shadow: 2px 2px 20px 1px;
  border-radius: 5px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 10px;
  margin: 0px;
  text-align: center;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  background-color: #EEEEEE;
  display: flex;
  height: fit-content;
}

.modal-body {
  position: relative;
  padding: 0px 10px;
}

.submit-btn {
  background-color: #3D96A6;
  color: white;
  border: none;
}

.submit-btn:disabled {
  background-color: #6C757D;
  cursor: default;
}

.btn-close, .btn-close:hover {
  padding: 5px;
  height: fit-content;
  border: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #3D96A6;
  background: transparent;
}

.model-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}

.waitinghide {
  display: none;
}
</style>
