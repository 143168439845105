<template>
  <div class="panel">
    <p class="panel-title" @click="toggleContent">{{ paneltitle }}</p>
    <div v-show="isVisible">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    paneltitle: String,
    defaultVisibility: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isVisible: this.defaultVisibility
    }
  },
  methods: {
    toggleContent() {
      this.isVisible = !this.isVisible;
    }
  }
}
</script>

<style scoped>
.panel-title {
  margin: 10px 0px 5px 10px;
  cursor: pointer;
}
.panel {
  margin: 10px;
  padding: 0px 5px 0px 5px;
  border-radius: 5px;
  border: 1px solid #444444;
  background-color: white;
}
</style>
