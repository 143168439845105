<template>
  <div class="card"
       v-bind:class="{
         unselected: cat == 'home' ? !$parent.$parent.$parent.homechk :
           cat == 'anywhere' ? !$parent.$parent.$parent.anychk :
           cat == 'errands' ? !$parent.$parent.$parent.errchk :
           cat == 'work' ? !$parent.$parent.$parent.workchk : false
       }"
       @click="toggleClickability"
       @mouseover="showIcon"
       @mouseleave="hideIcon">
    <div class="card-title" v-bind:class="cat">
      <span id="txtspan">
        <p class="card-title-text">{{ name }}</p>
      </span>
      <span id="icnspan">
        <i class="fa fa-edit edit" v-show="showEditIcon" @click="showModal"></i>
      </span>
    </div>
    <div class="card-content">
      <p>{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KBCard',
  props: {
    name: String,
    desc: String,
    cat: String,
    id: Number
  },
  data() {
    return {
      showEditIcon: false,
      clickableIcon: false
    }
  },
  methods: {
    toggleClickability() {
      this.clickableIcon = true;
    },
    showIcon() {
      if (!this.clickableIcon) {
        this.showEditIcon = true;
      }
    },
    hideIcon() {
      if (!this.clickableIcon) {
        this.showEditIcon = false;
      }
    },
    showModal() {
      this.$parent.$parent.$parent.showEditModal(this.name, this.desc, this.cat, this.id);
    }
  },
}
</script>


<style scoped>
p {
  margin: 0 0 0 0;
}
.card-title {
  font-size: 1.1em;
  padding: 2px 5px 2px 5px;
  border: 1px solid #444444;
  border-bottom: 0;
  border-radius: 5px 5px 0px 0px;
}
.card-content {
  font-size: 0.9em;
  padding: 5px;
  border: 1px solid #444444;
  border-radius: 0px 0px 5px 5px;
  background-color: #F8F8F8;
}
.card {
  white-space: initial;
  text-align: left;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.card:hover {
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}
.edit {
  display: none;
  padding: 5px 0px 5px 0px;
}
.card:hover  .edit {
  display: inline-block;
}
.card:hover  #txtspan {
  width: 190px;
}
#txtspan {
  display: inline-block;
}
#icnspan {
  display: inline-block;
  float: right;
}
.home{
  background-color: #93D2EA;
}
.anywhere{
  background-color: #9EE5C4;
}
/*.cardcol3 {
  background-color: #F79C85;
}
*/
.errands{
  background-color: #FCBF88;
}
.work{
  background-color: #D693B2;
}
.card-title-text {
  display: inline;
}
.unselected {
  display: none;
}

</style>
