<template>
  <div class="list" :style="cssVars">
    <div class="list-group">
      <div class="list-group-item" v-for="(element) in content" :key="element.id">
        <Waitingcard v-bind:class="{ ghost: $parent.frozen }" :name=element.title :desc=element.description :cat=element.category :id=element.id></Waitingcard>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Waitingcard from './Waiting-Card.vue'

export default {
  name: 'Waitinglist',
  components: {
    Waitingcard,
  },
  data() {
    return {
      waiting: false
    };
  },
  props: {
    title: String,
    content: Array,
    height: {
      type: Number,
      default: 600
    }
  },
  computed: {
    cssVars() {
      return {
        '--height': this.height + 'px'
      }
    }
  },
  methods: {
    checkMove: function() {
      return !this.$parent.frozen
    },
    freeze: function() {
      if (this.waiting == true) {
        this.$parent.frozen = true
      }
    },
    movedTo: function(evt) {
      this.waiting = true;
      setTimeout(this.freeze, 100);

      var out_data = new FormData();
      out_data.set('id', this.content[evt.newIndex].id);
      out_data.set('col', this.title);

      axios.post(this.$parent.move_url,
        out_data
      ).then(response => {
        if (response.status == 200) {
          this.$parent.confirmAction()
          this.waiting = false
          this.$parent.frozen = false
        }
        else {
          this.$parent.undoAction()
          this.waiting = false
          this.$parent.frozen = false
        }
      }).catch(err => {
        window.console.log(err)
        this.$parent.undoAction()
        this.waiting = false
        this.$parent.frozen = false
      })
    }
  }
}
</script>

<style scoped>
h4 {
  margin: 5px 0px 5px 0px;
  text-align: center;
}
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list {
  background-color: #EEEEEE;
  height: var(--height);
  width: 250px;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid #444444;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  -ms-overflow-style: none;
}
.list::-webkit-scrollbar {
  display: none;
}
.list-group {
  min-height: 200px;
}
</style>
