import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import DummyDashboard from './components/Dummy-Dashboard'
import Dashboard from './components/Dashboard.vue'
import Auth from '@okta/okta-vue'

Vue.use(Auth, {
  issuer: 'https://dev-857673.okta.com/oauth2/default',
  client_id: '0oa960l9jg3niNzK94x6',
  redirect_uri: window.location.origin + '/implicit/callback',
  scope: 'openid profile email'
})

Vue.use(VueRouter)
Vue.config.productionTip = false

const routes = [
  { path: '/implicit/callback', component: Auth.handleCallback() },
  { path: '/dummydashboard', component: DummyDashboard },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: {
    requiresAuth: true
  }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeEach(Vue.prototype.$auth.authRedirectGuard())

new Vue({
  el: '#app',
  router,
  render: h => h(App),
})
