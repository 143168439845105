<template>
  <div class="row">
    <div class="toprow">
      <button type="button" class="btn" @click="showModal">
        New Card
      </button>
    </div>
    <div class="col-3" v-for="(element) in board" :key="element.title">
      <Waitinglist :title=element.title :content=element.data :height=420></Waitinglist>
    </div>
    <modal :iswaiting=true v-show="isModalVisible" @close="closeModal"/>
    <editmodal :iswaiting=true :oldTitle="editTitle" :oldNotes="editNotes" :oldCategory="editCat" :oldId="editIdnum" v-show="isEditModalVisible" @close="closeEditModal"/>
  </div>
</template>

<script>
import axios from 'axios'
import Waitinglist from './Waiting-List.vue'
import modal from './New-KB-Modal.vue'
import editmodal from './Edit-KB-Modal.vue'

export default {
  name: 'Waitingon',
  components: {
    Waitinglist,
    modal,
    editmodal,
  },
  data() {
    return {
      board: null,
      cache_board: null,
      waiting: false,
      frozen: false,
      isModalVisible: false,
      isEditModalVisible: false,
      editTitle: 'temp',
      editNotes: 'temp',
      editCat: 'temp',
      editIdnum: 99,
      editIdx: Array,
      waitlist_url: String,
      newcard_url: String,
      editcard_url: String,
    };
  },
  methods: {
    confirmAction: function() {
      this.cache_board = JSON.parse(JSON.stringify(this.board))
    },
    undoAction: function() {
      this.board = JSON.parse(JSON.stringify(this.cache_board))
    },
    showModal: function() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showEditModal: function(editTitle, editNotes, editCat, editId) {
      this.findEditCard(editId);
      this.editTitle = editTitle;
      this.editNotes = editNotes;
      this.editCat = editCat;
      this.editIdnum = editId;
      this.isEditModalVisible = true;
    },
    closeEditModal() {
      this.isEditModalVisible = false;
    },
    findEditCard: function(editId) {
      for (let i = 0; i < Object.keys(this.board).length; i++) {
        for (let j = 0; j < Object.keys(this.board[i]['data']).length; j++) {
          if (this.board[i]['data'][j]['id'] == editId) {
            this.editIdx = [i, j];
          }
        }
      }
    },
    addCard: function(newcard, callback) {
      this.board["0"]["data"].push(newcard);
      callback();
    },
    updateCard: function(updatecard, callback) {
      this.board[this.editIdx[0]]['data'][this.editIdx[1]]['title'] = updatecard['title'];
      this.board[this.editIdx[0]]['data'][this.editIdx[1]]['description'] = updatecard['description'];
      this.board[this.editIdx[0]]['data'][this.editIdx[1]]['category'] = updatecard['category'];
      this.board[this.editIdx[0]]['data'][this.editIdx[1]]['id'] = updatecard['id'];
      callback();
    },
    deleteCard: function(callback) {
      this.board[this.editIdx[0]]['data'].splice(this.editIdx[1],1);
      callback();

    },
    freeze: function() {
      if (this.waiting == true) {
        this.frozen = true
      }
    },
  },
  mounted: function() {
    if (this.$parent.$parent.$vnode.tag.includes('DummyDashboard')) {
      this.waitlist_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/dummy_waiting"
      this.newcard_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/new_card_dummy"
      this.editcard_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/success_dummy"
      this.del_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/success_dummy"
    }
    else {
      this.waitlist_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/get_waiting"
      this.newcard_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/new_card"
      this.editcard_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/edit_card"
      this.del_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/delete_card"
    }
    axios.get(this.waitlist_url).then(
      response => (
        this.board = response.data,
        this.cache_board = JSON.parse(JSON.stringify(response.data))
      )
    )
  }
};
</script>

<style scoped>
.col-3 {
  display: inline-block;
  margin: 5px 5px 10px 5px;
  float: left;
}
.row {
  white-space: nowrap;
  height: 460px;
  padding: 0px 5px 0px 5px;
}
#right-col {
  margin: 5px;
  height: 400px;
  display: inline-block;
  float: left;
}
.toprow {
  padding: 0px 5px;
}
/*.fa-check-square {
display: block;
color: #3D96A6;
top: 50%;
left: 50%;
}*/
.card {
  padding: 100px;
  display: none;
}
.btn {
  left: 50%
}
h4 {
  margin: 5px 0px 5px 0px;
  text-align: center;
}
.cbox-lab {
  display: inline-block;
  margin: 0px 0px 0px 20px;
}

#checkboxes {
  display: inline-block;
}
.cbox {
  display: inline;
  vertical-align: middle;
}
.clab-text {
  display: inline;
  vertical-align: middle;
}
</style>
