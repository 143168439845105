<template>
  <div class="card">
    <div class="card-title">
      <span id="txtspan">
        <p class="card-title-text">{{ name }}</p>
      </span>
      <span id="icnspan">
        <i class="fa fa-edit edit" @click="showModal"></i>
      </span>
    </div>
    <div class="card-content">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Waitingcard',
  props: {
    name: String,
    desc: String,
    cat: String,
    id: Number
  },
  data() {
    return {
      checked: this.$parent.$parent.$parent.homechk
    }
  },
  methods: {
    showModal: function() {
      this.$parent.$parent.showEditModal(this.name, this.desc, this.cat, this.id);
    }
  },
}
</script>

<style scoped>
p {
  margin: 0 0 0 0;
}
.card-title {
  font-size: 1.1em;
  padding: 2px 5px 2px 5px;
  border: 1px solid #444444;
  border-bottom: 0;
  border-radius: 5px 5px 0px 0px;
  background-color: #F79C85;
}
.card-content {
  font-size: 0.9em;
  padding: 5px;
  border: 1px solid #444444;
  border-radius: 0px 0px 5px 5px;
  background-color: #F8F8F8;
}
.card {
  white-space: initial;
  text-align: left;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.card:hover {
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}
.edit {
  display: none;
  padding: 5px 0px 5px 0px;
}
.card:hover  .edit {
  display: inline-block;
}
.card:hover  #txtspan {
  width: 190px;
}
#txtspan {
  display: inline-block;
}
#icnspan {
  display: inline-block;
  float: right;
}
/*.cardcol3 {
}
 */
.card-title-text {
  display: inline;
}
.unselected {
  display: none;
}

</style>
