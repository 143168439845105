<template>
  <div id="dashboard">
    <div class="content">
      <Panel id='flash' paneltitle='Flash Cards'>
      <component :is="child_component2"></component>
      </Panel>
      <Panel id='kanban' paneltitle='KANBAN'>
      <component :is="child_component"></component>
      </Panel>
      <div id='feedly-row'>
        <Panel id='feedly' paneltitle='Feedly - Highlight'>
          <component :is="child_component5" dataurl='https://brain-backend-qsyqs6472a-ts.a.run.app/v1/feedly_memory'></component>
        </Panel>
        <Panel id='feedly' paneltitle='Feedly - Read Later'>
          <component :is="child_component5" dataurl='https://brain-backend-qsyqs6472a-ts.a.run.app/v1/feedly_saved'></component>
        </Panel>
      </div>
      <div id='waiting-row'>
        <Panel id='waitingon' paneltitle='Waiting On'>
        <component :is="child_component3"></component>
        </Panel>
        <Panel id='memory' paneltitle='Memory - Film'>
        <component :is="child_component4" dataurl='https://brain-backend-qsyqs6472a-ts.a.run.app/v1/imdb_memory'></component>
        </Panel>
        <Panel id='memory' paneltitle='Memory - Book'>
        <component :is="child_component4" dataurl='https://brain-backend-qsyqs6472a-ts.a.run.app/v1/goodreads_memory'></component>
        </Panel>
      </div>
      <Panel id='calendar' paneltitle='Calendar' :defaultVisibility=false>
        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FLondon&mode=WEEK&showTitle=0&showDate=0&showPrint=0&showCalendars=0&showTz=1&src=YW5kcmV3Lmoua3VvQGdtYWlsLmNvbQ&src=YTRiMzIxYmZlYjUzMzcyZDJhMThkZDA3ZGY1YWEzNGI5MDYyZDFkZjQ2MGE2MGE3OGUzNGFlNjIxNjhmYWI3OEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=bjc4NXJxNDh0NWczOWdzbjg3ZGU2azU1aWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=NDd2cXV1ZXVuZDY3bGI1aDJhaHVzbjlzMG9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZmFtaWx5MTUwMTcxODQ0NTM0MTg0Mjk4NTdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZW4udWsjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%23EF6C00&color=%23D81B60&color=%238E24AA&color=%23F6BF26&color=%230B8043" style="border-width:0" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
      </Panel>
      <Panel id='miro' paneltitle='Miro Board' :defaultVisibility=false>
        <iframe width="100%" height="500" src="https://miro.com/app/live-embed/uXjVNHj8N8g=/?moveToViewport=-9120,-2488,6499,4482&embedId=596144085323" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>
      </Panel>
    </div>
  </div>
</template>

<script>
import Panel from './Panel.vue'
import Kanban from './Kanban.vue'
import Flashcard from './Flash-Card.vue'
import Memory from './Memory.vue'
import Feedly from './Feedly.vue'
import Waitingon from './WaitingOn.vue'

export default {
  name: 'Dashboard',
  components: {
    Panel,
    Kanban,
    Flashcard,
    Waitingon,
    Memory,
    Feedly
  },
  data() {
    return {
      child_component: 'kanban',
      child_component2: 'flashcard',
      child_component3: 'waitingon',
      child_component4: 'memory',
      child_component5: 'feedly'
    }
  },
}
</script>

<style>
#dashboard {
  text-align: center;
  min-height: 500px;
  color: #404040;
}
.navbar {
  /*
  background-color: #2C407C;
   */
background-color: #3D96A6;
position: relative;
display: flex;
align-items: center;
padding: 1rem 1rem;
min-width: 955px;
}

.navtitle {
  margin: 0px 10px;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}
.fa-brain {
  color: white;
}

.katex-display {
  display: inline;
}

.katex-display>.katex {
  display: inline;
}

.katex-display>.katex>.katex-html {
  display: inline;
}
.btn {
  padding: 10px;
  margin: 2px;
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}
.content {
  width: 955px;
  display: inline-block;
  text-align: left;
}
#waitingon {
  width: 282px;
}
#waiting-row {
  display: flex;
  margin: -10px 0px;
}
#feedly-row {
  display: flex;
  margin: -10px 0px;
}
#feedly {
  margin: 10px 0px 10px 10px;
  width: 462.5px;
}
#memory {
  margin: 10px 10px 10px 0px;
  width: 316.5px;
}

</style>
