<template>
  <div id="dashboard">
  <h2>Dummy Dashboard</h2>
  <p>This is a demo of the brainv2 app with dummy data.</p>
    <div class="content">
      <Panel id='flash' paneltitle='Flash Cards'>
      <component :is="child_component2"></component>
      </Panel>
      <Panel id='kanban' paneltitle='KANBAN'>
      <component :is="child_component"></component>
      </Panel>
      <div id='waiting-row'>
        <Panel id='waitingon' paneltitle='Waiting On'>
        <component :is="child_component3"></component>
        </Panel>
        <Panel id='memory' paneltitle='Memory - Film'>
        <component :is="child_component4" dataurl='https://brain-backend-qsyqs6472a-ts.a.run.app/v1/imdb_memory_dummy'></component>
        </Panel>
        <Panel id='memory' paneltitle='Memory - Book'>
        <component :is="child_component4" dataurl='https://brain-backend-qsyqs6472a-ts.a.run.app/v1/goodreads_memory_dummy'></component>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from './Panel.vue'
import Kanban from './Kanban.vue'
import Flashcard from './Flash-Card.vue'
import Memory from './Memory.vue'
import Waitingon from './WaitingOn.vue'

export default {
  name: 'DummyDashboard',
  components: {
    Panel,
    Kanban,
    Flashcard,
    Waitingon,
    Memory
  },
  data() {
    return {
      child_component: 'kanban',
      child_component2: 'flashcard',
      child_component3: 'waitingon',
      child_component4: 'memory'
    }
  },
}
</script>

<style>
#dashboard {
  text-align: center;
  min-height: 500px;
  color: #404040;
}
.navbar {
  /*
  background-color: #2C407C;
   */
background-color: #3D96A6;
position: relative;
display: flex;
align-items: center;
padding: 1rem 1rem;
min-width: 955px;
}

.navtitle {
  margin: 0px 10px;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}
.fa-brain {
  color: white;
}

.katex-display {
  display: inline;
}

.katex-display>.katex {
  display: inline;
}

.katex-display>.katex>.katex-html {
  display: inline;
}
.btn {
  padding: 10px;
  margin: 2px;
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}
.content {
  width: 955px;
  display: inline-block;
  text-align: left;
}
#waitingon {
  width: 282px;
}
#waiting-row {
  display: flex;
  margin: -10px 0px;
}
#articles {
  margin: 10px 10px 10px 0px;
  width: 643px;
}

</style>
