<template>
  <div class="row">
    <div>
      <a :href="link_url" target="_blank">
        <h5> {{ title }} </h5>
      </a>
      <div id=article-preview>
          <img id="article-img" :src="img_url"
            onerror="this.onerror=null; this.src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/390px-No-Image-Placeholder.svg.png';"
          >
        <p id='article-content'>{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Feedly',
  data() {
    return {
      title: '',
      content: 'No preview available.',
      link_url: '',
      img_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/390px-No-Image-Placeholder.svg.png',
    }
  },
  props: {
    dataurl: String
  },
  mounted: function() {
    axios.get(this.dataurl).then((response) => {
        this.title = response.data['title'];
        if (response.data['content'] != null) {
          this.content = response.data['content'];
        }
        if (response.data['board'] == 'Saved For Later') {
          this.link_url = 'https://feedly.com/i/entry/' + response.data['id'];
        } else {
          this.link_url = response.data['url'];
        }
        if (response.data['image'] != null) {
          this.img_url = response.data['image'];
        }
    });
  }
}
</script>

<style scoped>
body {
  background: #EEE;
}
.row {
  width: 100%;
  height: 100%;
  padding: 0px 10px 0px 10px;
  /* text-align: center; */
}
#article-preview {
  margin: 10px 0px;
  display: flex;
  align-items: top;
}
#article-img {
  max-width: 30%;
  height: auto;
  object-fit: contain;
  align-content: top;
  margin-right: 10px;
  display: flex;
}
#article-content {
  display: flex;
  font-size: 0.9em;
}
h5 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
</style>
