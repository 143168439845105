<template>
  <div id="app">
    <div class=navbar>
      <a id="logo-holder-link" href=''>
      <div id="logo-holder">
        <i class="fa fa-brain fa-2x"></i>
        <p class=navtitle>Brain-V2</p>
      </div>
      </a>
    </div>
    <br>
    <div>
      <a href="dummydashboard" v-if="!activeUser"><button class="btn" id="dummydashbtn">Dummy Dashboard</button></a>
      <a href="#" v-if="!activeUser" @click.prevent="login"><button class="btn" id="loginbtn">Login</button></a>
      <div v-else>
        Welcome {{ activeUser.email }}
        <a href="dashboard"><button class="btn" id="dashbtn">Dashboard</button></a>
        <a href="#" @click.prevent="logout"><button class="btn" id="logoutbtn">Logout</button></a>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      activeUser: null
    }
  },
  async created () {
    await this.refreshActiveUser()
  },
  watch : {
    '$route': 'refreshActiveUser'
  },
  methods: {
    login () {
      this.$auth.loginRedirect()
    },
    async refreshActiveUser () {
      this.activeUser = await this.$auth.getUser()
    },
    async logout () {
      await this.$auth.logout()
      await this.refreshActiveUser()
      this.$router.push('/')
    }
  },
  mounted: function() {
    var siteWidth = 1280;
    var scale = screen.width / siteWidth;

    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width='+siteWidth+', initial-scale='+scale+'');
  }
}
</script>

<style>
#app {
  text-align: center;
  color: #404040;
}
.navbar {
  background-color: #3D96A6;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  min-width: 955px;
}

.navtitle {
  margin: 0px 10px;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}
.fa-brain {
  color: white;
}

#logo-holder {
  display: flex;
}

#logo-holder-link {
  text-decoration: none;
}

.katex-display {
  display: inline;
}

.katex-display>.katex {
  display: inline;
}

.katex-display>.katex>.katex-html {
  display: inline;
}
.btn {
  padding: 10px;
  margin: 2px;
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}
#loginbtn {
  color: #404040;
  background-color: #9EE5C4;
  border-color: #9EE5C4;
}
#logoutbtn {
  color: #404040;
  background-color: #F79C85;
  border-color: #F79C85;
}

</style>
