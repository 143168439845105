<template>
	<div class="row">
		<div id="main-content" class="row">
			<div id="card">
				<div class="panel-group">
					<div v-for="(card, index) in cards" :key="index" :ref="'card-'+index" class="panel panel-default">
						<div v-if="index == card_idx" >
							<h4 class="panel-title">
								<a id="question" v-katex:auto v-on:click="showCard" href="#">{{ card.question }}</a>
							</h4>
							<div id="collapse1" class="collapse">
								<div v-katex:auto="{ display: false }">
									{{ card.answer }}
								</div>
								<br>
								<button type="button" id="blackout_btn" class="btn fdbk" v-on:click='giveFeedback(0)'>Blackout</button>
								<button type="button" id="prompted_btn" class="btn fdbk" v-on:click='giveFeedback(1.5)'>Prompted</button>
								<button type="button" id="hazy_btn" class="btn fdbk" v-on:click='giveFeedback(3)'>Hazy</button>
								<button type="button" id="perfect_btn" class="btn fdbk" v-on:click='giveFeedback(5)'>Perfect</button>
							</div>
						</div>
					</div>
          <div class="panel panel-default">
						<div v-if="cards_left == 0" >
							<h4 class="panel-title">
								<a id="question" href="#">All Done :)</a>
							</h4>
            </div>
          </div>
				</div>
			</div>
			<div id="nxt_page">
				<button id="next_btn" type="button" class="btn" v-on:click="nextCard()">Next Card</button>
				<p id=card_count>{{ this.cards_left }} cards left</p>
			</div>
		</div>
	</div>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueKatex from 'vue-katex'
import 'katex/dist/katex.min.css';

Vue.use(VueKatex)

export default {
	name: 'Flashcard',
	data() {
		return {
			card_idx: 0,
			cards_left: 0,
			cards: null,
      dud_cards: [],
      qanda_url: String,
      fback_url: String
		}
	},
	methods: {
		showCard: function() {
			this.$refs['card-'+this.card_idx][0].querySelector('#collapse1').classList.remove('collapse');
		},
		nextCard: function() {
      if (this.cards_left > 0) {
        if (this.card_idx < this.cards.length - 1) {
          this.card_idx = this.card_idx + 1
        }
        else {
          this.card_idx = 0
        }
        if (this.dud_cards.includes(this.card_idx)) {
          this.nextCard()
        }
      }
      else {
        this.card_idx = -1
      }
      },
		giveFeedback: function(value) {
			var out_data = new FormData();
			out_data.set('node_id', this.cards[this.card_idx]['node_id']);
			out_data.set('q', value);

			axios.post(this.fback_url,
				out_data
			).then( response => (
			// eslint-disable-next-line no-console
			console.log(response)
			))
      this.dud_cards.push(this.card_idx)
      this.cards_left = this.cards_left - 1
			this.nextCard();
		}

	},
	mounted: function() {
    if (this.$parent.$parent.$vnode.tag.includes('DummyDashboard')) {
      this.qanda_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/qandadummy"
      this.fback_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/dummyfeedback"
    }
    else {
      this.qanda_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/qanda2day"
      this.fback_url = "https://brain-backend-qsyqs6472a-ts.a.run.app/v1/feedback"
    }
		axios.get(this.qanda_url).then(
			response => (
				this.cards = response.data,
				this.cards_left = this.cards.length
			)
		)
	}
}
</script>

<style scoped>
body {
	background: #EEE;
}
.row {
	width: 100%;
	padding: 0px 5px 0px 5px;
}
.collapse {
	display: none;
}
.panel-group {
	padding: 10px;
	background-color: #EEEEEE;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.panel-title {
	margin: 0 0 1rem 0;
}
#blackout_btn {
	background-color: #F79C85;
	border-color: #F79C85;
}
#prompted_btn {
	background-color: #FCBF88;
	border-color: #FCBF88;
}
#hazy_btn {
	background-color: #93D2EA;
	border-color: #93D2EA;
}
#perfect_btn {
	background-color: #9EE5C4;
	border-color: #9EE5C4;
}
#question {
	color: #404040;
}
#main-content {
	padding: 0px 0px 10px 0px;
	display: flex;
}
#card {
	flex: 0 0 85%;
}
#nxt_page {
	flex: 1;
	text-align: center;
	padding: 10px;
}
.fdbk {
  color: #404040;
}

#card_count {
	vertical-align: text-bottom;
}
</style>
